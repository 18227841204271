<template>
  <div>
    <spinner v-if="loading" color="#000" />
    <template v-else>
      <entity-card
        :fields="fields"
        :entity="entity"
        :title="entity.name"
        big-heading
      >
        <template #requestBody>
          <pre>{{ entity.requestBody }}</pre>
        </template>
        <template #responseBody>
          <pre>{{ entity.responseBody }}</pre>
        </template>
      </entity-card>
    </template>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import axios from '@/services/axios';
import Spinner from '@/components/utils/spinner.vue';
import EntityCard from '@/components/shared/EntityCard.vue';
import { formatDate } from '@/utils/date-utils';

export default {
  name: 'RegisterRequestLogDetail',
  components: {
    EntityCard,
    Spinner,
  },
  setup() {
    const route = useRoute();
    const entity = ref(null);
    const loading = ref(true);
    const { t } = useI18n();

    const fields = [
      { key: 'createdAt', label: t('requestLog.createdAt.label'), formatter: (v) => formatDate(v, 'dd.MM.yyyy HH:mm:ss') },
      { key: 'time', label: t('requestLog.time.label'), formatter: (v) => `${v}ms` },
      { key: 'code', label: t('requestLog.code.label') },
      { key: 'url', label: t('requestLog.url.label') },
      { key: 'method', label: t('requestLog.method.label') },
      { key: '', label: '' },
      { key: 'requestBody', label: t('requestLog.requestBody.label') },
      { key: 'responseBody', label: t('requestLog.responseBody.label') },
    ];

    const loadData = async () => {
      loading.value = true;

      try {
        const { data } = await axios.get(`/register_request_logs/${route.params.id}`);
        entity.value = data;
      } catch (err) {
        console.error(err, err.message, err.response);
      }

      loading.value = false;
    };

    loadData();

    return {
      t,
      entity,
      fields,
      loading,
      loadData,
    };
  },
};
</script>

<style lang="scss">
pre {
  white-space: pre-wrap;
}
</style>
